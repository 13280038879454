<template>
	<div data-component="header-small">
		<app-logo />
		<menu-button
			icon="burger"
		/>
	</div>
</template>

<script>

	import AppLogo     from '@/components/ui/header/AppLogo';
	import MenuButton  from '@/components/ui/header/headerSmall/MenuButton';

	export default {
		components: {
			AppLogo,
			MenuButton
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='header-small'] {
		position:relative;
		@include grid;
		align-items:center;
		height:rem(68);
		border-bottom:5px solid $c-brand-blue;
		display:flex;
		@include mq('sm') {
			display:none;
		}
		&:after {
			content:'';
			display:block;
			position:absolute;
			right:0;
			bottom:-9px;
			left:0;
			height:3px;
			background-color:$c-brand-red;
		}
		[data-component='app-logo'] {
			margin:auto 32px auto 0;
		}
		// [data-component='nav-menu'] {
		// 	margin:auto 32px auto 0;
		// }
		[data-component='menu-button'] {
			margin:auto 0 auto auto;
		}
	}
</style>
