<template>
	<nav
		data-component="nav-menu"
	>
		<router-link
			v-for="(link, key) in getLinks"
			:key="key"
			:to="link.route"
		>
			{{ link.text }}
		</router-link>
	</nav>
</template>

<script>

	// import AppLogo from '@/components/ui/header/AppLogo';

	export default {
		// components: {
		// 	AppLogo
		// }
		data: () => ({}),
		computed: {
			getLinks () {
				return [
					{
						text: 'Find a course',
						route: '/browse-courses'
					},
					(this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'My courses',
							route: '/my-courses'
						} : false
					),
					{
						text: 'About',
						route: '/about'
					},
					(this.$store.getters['auth/getIsTeacher'] ?
						{
							text: 'Teaching',
							route: '/teaching'
						} : false
					),
					(this.$store.getters['auth/getIsAdmin'] ?
						{
							text: 'Admin',
							route: '/admin'
						} : false
					),
					(!this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'Sign in',
							route: '/login'
						} : false
					)
				].filter(Boolean);
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='nav-menu'] {
		display:flex;
		a {
			margin:0 rem(2);
			padding:rem(8) rem(10);
			font-family:'Montserrat', sans-serif;
			font-size:rem(15);
			font-weight:700;
			color:$c-brand-blue;
			border-radius:8px;
			transition:background 0.2s ease;
			@media (min-width:965px) {
				margin:0 rem(4);
				padding:rem(10) rem(14);
				font-size:rem(16);
			}
			@media (min-width:1080px) {
				padding:rem(12) rem(22);
				font-size:rem(18);
			}
			&:first-child {
				margin-left:0;
			}
			&:last-child {
				margin-right:0;
			}
			&.router-link-active,
			&:hover {
				background-color:$c-brand-grey-lighter-7;
			}
		}
	}
</style>
