<template>
	<div data-component="header-large">
		<div data-element="container">
			<div data-element="wrapper">
				<app-logo />
				<nav-menu />
				<profile-button />
			</div>
		</div>
	</div>
</template>

<script>

	import AppLogo        from '@/components/ui/header/AppLogo';
	import NavMenu        from '@/components/ui/header/headerLarge/NavMenu';
	import ProfileButton  from '@/components/ui/header/headerLarge/ProfileButton';

	export default {
		components: {
			AppLogo,
			NavMenu,
			ProfileButton
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='header-large'] {
		position:relative;
		display:none;
		border-bottom:8px solid $c-brand-blue;
		@include mq('sm') {
			display:block;
		}
		&:before {
			content:'';
			display:block;
			position:absolute;
			right:0;
			bottom:-11px;
			left:0;
			height:3px;
			background-color:$c-white;
		}
		&:after {
			content:'';
			display:block;
			position:absolute;
			right:0;
			bottom:-16px;
			left:0;
			height:5px;
			background-color:$c-brand-red;
		}
		[data-element='container'] {
			@include grid-container;
			[data-element='wrapper'] {
				@include grid;
				display:flex;
				align-items:center;
				height:rem(100);
				[data-component='app-logo'] {
					margin:auto rem(12) auto 0;
				}
				[data-component='nav-menu'] {
					margin:auto 0 auto auto;
				}
				[data-component='profile-button'] {
					margin:auto 0 auto rem(2);
					@media (min-width:965px) {
						margin:auto 0 auto rem(10);
					}
					@media (min-width:1080px) {
						margin:auto 0 auto rem(12);
					}
				}
			}
		}
	}
</style>
