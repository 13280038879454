<template>
	<component
		data-component="drawer-item"
		:is="getComponent"
		v-bind="getProps"
		@click="click"
	>
		{{ text }}
	</component>
</template>

<script>

	import action from '@/mixins/action';

	export default {
		mixins: [action],
		props: {
			text: {
				type: String,
				default: ''
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='drawer-item'] {
		width:auto;
		margin:0 auto rem(4) 0;
		padding:rem(12) rem(18);
		outline:none;
		border:none;
		border-radius:8px;
		background:transparent;
		font-family:'Montserrat', sans-serif;
		font-size:rem(22);
		line-height:1;
		font-weight:700;
		color:$c-brand-blue;
		user-select:none;
		cursor:pointer;
		&.router-link-active,
		&:hover {
			background-color:$c-brand-grey-lighter-7;
		}
	}

</style>
