<template>
	<nav
		data-component="profile-menu"
		v-if="getIsVisible"
		v-click-outside="clickOutside"
	>
		<div
			v-if="getFullName"
			data-element="menu-item"
		>
			{{ getFullName }}
		</div>
		<router-link
			to="/my-profile"
			data-element="menu-item"
			data-action="true"
		>
			Edit profile
		</router-link>
		<div
			@click="logOut"
			data-element="menu-item"
			data-action="true"
		>
			Log out
		</div>
	</nav>
</template>

<script>

	export default {
		data: () => ({}),
		computed: {
			getIsVisible () {
				return this.$store.state.ui.profileMenuIsVisible;
			},
			getFullName () {
				return this.$store.getters['auth/getFullName'];
			},
			getIsAuthenticated () {
				return this.$store.getters['auth/getIsAuthenticated'];
			}
		},
		mounted () {
			window.addEventListener('resize', this.onResize);
		},
		beforeDestroy () {
			window.removeEventListener('resize', this.onResize);
		},
		methods: {
			onResize () {
				const mq = window.matchMedia('(min-width:899px)');
				if (mq?.matches) {
					return;
				}
				if (!this.getIsVisible) {
					return;
				}
				this.$store.commit('ui/setProfileMenuIsHidden');
			},
			logOut () {
				this.$store.dispatch('auth/logOut');
			},
			clickOutside () {
				this.$store.commit('ui/toggleProfileMenuIsVisible');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='profile-menu'] {
		display:block;
		width:auto;
		padding:rem(4);
		background-color:$c-white;
		border-radius:4px;
		box-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.72);
		cursor:default;
		[data-element='menu-item'] {
			display:block;
			margin:rem(2);
			padding:rem(12) rem(14);
			font-size:rem(18);
			line-height:1;
			font-weight:700;
			color:$c-brand-blue;
			border-radius:4px;
			user-select:none;
			white-space:nowrap;
			&[data-action] {
				cursor:pointer;
				&.router-link-active,
				&:hover {
					background-color:$c-brand-blue-lighter-4;
				}
			}
		}
	}
</style>
