<template>
	<div data-layout="default">
		<app-header />
		<div data-element="container">
			<div data-element="breadcrumb-wrapper">
				<verification-banner />
				<breadcrumbs />
			</div>
			<transition
				name="fade"
			>
				<loading
					v-show="getLoadingIsVisible"
				/>
			</transition>
			<transition name="fade" mode="out-in">
				<component
					:is="getRouteComponent"
					:key="$route.fullPath"
				/>
			</transition>
		</div>
		<app-footer />
		<drawer />
		<notification />
		<modal />
		<screen-recorder-controls />
	</div>
</template>

<script>

	import AppHeader from '@/components/ui/header/AppHeader';
	import VerificationBanner from '@/components/profile/VerificationBanner';
	import Breadcrumbs from '@/components/ui/Breadcrumbs';
	import Drawer from '@/components/ui/header/headerSmall/Drawer';
	import Loading from '@/components/ui/Loading';
	import Notification from '@/components/ui/Notification';
	import AppFooter from '@/components/ui/AppFooter';
	import FourOhFour from '@/pages/404';
	import Modal from '@/components/ui/modal/Modal';
	import ScreenRecorderControls from '@/components/screenRecorder/ScreenRecorderControls';

	export default {
		components: {
			AppHeader,
			VerificationBanner,
			Breadcrumbs,
			Loading,
			FourOhFour,
			AppFooter,
			Drawer,
			Notification,
			Modal,
			ScreenRecorderControls
		},
		computed: {
			getLoadingIsVisible () {
				return this.$store.getters['ui/getLoadingIsVisible'];
			},
			getRouteComponent () {
				if (this.$store.getters['ui/getErrorIsVisible']) {
					return 'FourOhFour';
				}
				return 'router-view';
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-layout='default'] {
		display:flex;
		flex-direction:column;
		min-height:100vh;
		height:stretch;
		[data-element='container'] {
			@include grid-container;
			flex-grow:1;
			[data-element='breadcrumb-wrapper'] {
				@include grid;
				[data-component='verification-banner'] {
					margin-top:rem(24);
				}
				::v-deep [data-component='breadcrumbs'] {
					@include cols(12);
					margin-top:rem(24);
					margin-bottom:rem(48);
				}
			}
		}
	}
</style>
