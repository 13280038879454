<template>
	<div data-component="app-footer">
		<div data-element="container">
			<div data-element="wrapper">
				<p data-element="copyright">
					{{ getCopyright }}
				</p>
				<p data-element="app-version">
					{{ getAppVersion }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		computed: {
			getAppVersion () {
				return `Bridge128 v${process.env.VUE_APP_VERSION}`;
			},
			getCopyright () {
				return `Bridge128 © ${this.getDateString}`;
			},
			getDateString () {
				const startYear = '2022';
				const currentYear = new Date().getFullYear().toString();
				let dateString = startYear;
				if (currentYear !== startYear) {
					dateString = `${dateString}–${currentYear.slice(-2)}`;
				}
				return dateString;
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='app-footer'] {
		padding-top:rem(48);
		padding-bottom:rem(48);
		background-color:$c-brand-blue;
		color:$c-brand-white;
		[data-element='container'] {
			@include grid-container;
			[data-element='wrapper'] {
				@include grid;
				[data-element='copyright'] {
					@include cols(12);
					font-size:rem(22);
					margin-bottom:rem(4);
				}
				[data-element='app-version'] {
					@include cols(12);
					font-size:rem(10);
				}
			}
		}
	}
</style>
