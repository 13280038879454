<template>
	<div
		data-component="profile-button"
		v-if="getShowProfileButton"
		:data-open="getIsOpen"
	>
		<div data-element="wrapper">
			<icon
				icon="user"
				colour="blue"
				@click.native="toggleProfileMenuIsVisible"
			/>
		</div>
		<profile-menu />
	</div>
</template>

<script>

	import Icon         from '@/components/ui/Icon';
	import ProfileMenu  from '@/components/ui/header/headerLarge/ProfileMenu';

	export default {
		components: {
			Icon,
			ProfileMenu
		},
		data: () => ({

		}),
		computed: {
			getIsAuthenticated () {
				return this.$store.getters['auth/getIsAuthenticated'];
			},
			getShowProfileButton () {
				return this.getIsAuthenticated;
			},
			getIsOpen () {
				return this.$store.state.ui.profileMenuIsVisible;
			}
		},
		methods: {
			toggleProfileMenuIsVisible () {
				this.$store.commit('ui/toggleProfileMenuIsVisible');
			},
			logOut () {
				this.$store.dispatch('auth/logOut');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='profile-button'] {
		position:relative;
		display:flex;
		align-items:center;
		cursor:pointer;
		user-select:none;
		transition:background 0.2s ease;
		border-radius:8px;
		padding:rem(4);
		&[data-open],
		&:hover {
			background-color:$c-brand-grey-lighter-7;
		}
		[data-component='icon'] {
			width:rem(32);
			height:auto;
			@media (min-width:965px) {
				width:rem(38);
			}
			@media (min-width:1080px) {
				width:rem(42);
			}
		}
		[data-component='profile-menu'] {
			position:absolute;
			z-index:2;
			top:63px; right:0;
		}
	}

</style>
