<template>
	<div data-component="menu-button">
		<icon
			size="sm"
			colour="blue"
			:icon="icon"
			@click.native="toggleDrawer"
		/>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			icon: {
				type: String,
				default: 'burger'
			}
		},
		computed: {},
		methods: {
			toggleDrawer () {
				this.$store.commit('ui/toggleDrawerIsVisible');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='menu-button'] {
		user-select:none;
		cursor:pointer;
		display:flex;
		align-items:center;
		cursor:pointer;
		user-select:none;
		transition:background 0.2s ease;
		border-radius:8px;
		padding:rem(4);
		&:hover {
			background-color:$c-brand-grey-lighter-7;
		}
		[data-component='icon'] {
			width:rem(32);
			height:auto;
			@media (min-width:965px) {
				width:rem(38);
			}
			@media (min-width:1080px) {
				width:rem(42);
			}
		}
	}
</style>
