<template>
	<div
		v-show="getControlsVisible"
		data-component="screen-recorder-controls"
		:data-transparent="getControlsTransparency"
	>
		<div data-element="actions">
			<div
				v-if="getRecordingInProgress"
				data-element="recorder-button"
			>
				<icon
					icon="stop"
					colour="red"
					@click.native="stopRecording"
				/>
			</div>
			<btn
				v-else
				text="Start recording"
				size="sm"
				@click.native="startRecording"
			/>
			<template v-if="getRecordingInProgress">
				<btn
					v-if="getRecordingPaused"
					text="Resume"
					size="sm"
					btnId="resume"
					@click.native="resumeRecording"
				/>
				<div
					v-else
					data-element="recorder-button"
				>
					<icon
						icon="pause"
						colour="blue"
						@click.native="pauseRecording"
					/>
				</div>
			</template>
			<btn
				v-else
				text="Close"
				size="sm"
				btnId="close"
				:isDisabled="getCloseDisabled"
				@click.native="hideControls"
			/>
		</div>
		<checkbox-field
			v-if="!getRecordingInProgress"
			name="Allow microphone?"
			id="microphonePermission"
			:checked="getMicrophonePermission"
			:disabled="!!getRecordingInProgress"
			@change="setMicrophonePermission"
		/>
		<select-field
			v-if="getIsDebugMode"
			name="Video encoding format"
			:disabled="!!getRecordingInProgress"
			:options="availableMimetypes"
			:value="getMimeType"
			@input="setMimeType"
		/>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';
	import Btn from '@/components/ui/Btn';
	import CheckboxField from '@/components/forms/CheckboxField';
	import SelectField from '@/components/forms/SelectField';
	import actionClick from '@/mixins/actionClick';
	import routeParams from '@/mixins/routeParams';
	import { DEFAULT_MIMETYPE } from '@/services/screenRecorderService';

	export default {
		components: {
			Icon,
			Btn,
			CheckboxField,
			SelectField
		},
		mixins: [actionClick, routeParams],
		props: {
		},
		data: () => ({
			availableMimetypes: [
				{
					text: 'Default (vp8)',
					value: DEFAULT_MIMETYPE
				},
				{
					text: 'h264',
					value: 'video/webm;codecs=h264'
				},
				{
					text: 'vp9',
					value: 'video/webm;codecs=vp9'
				}
			]
		}),
		computed: {
			getIsDebugMode () {
				return window.location.href.includes('?debug=true');
			},
			getMimeType () {
				return this.$store.getters['screenRecorder/getMimeType'];
			},
			getMicrophonePermission () {
				return !!this.$store.getters['screenRecorder/getMicrophonePermission'];
			},
			getControlsVisible () {
				return this.$store.getters['screenRecorder/getControlsVisible'];
			},
			getRecordingInProgress () {
				return this.$store.getters['screenRecorder/getRecordingInProgress'];
			},
			getRecordingPaused () {
				return this.$store.getters['screenRecorder/getRecordingPaused'];
			},
			getCloseDisabled () {
				return this.getRecordingInProgress ? 'close' : '';
			},
			getControlsTransparency () {
				return this.getRecordingInProgress && !this.getRecordingPaused;
			}
		},
		methods: {
			setMimeType (value) {
				this.$store.dispatch('screenRecorder/setMimeType', value);
			},
			setMicrophonePermission (value) {
				this.$store.dispatch('screenRecorder/setMicrophonePermission', value);
			},
			startRecording () {
				this.$store.dispatch('screenRecorder/startRecording');
			},
			stopRecording () {
				this.$store.dispatch('screenRecorder/stopRecording');
			},
			pauseRecording () {
				this.$store.dispatch('screenRecorder/pauseRecording');
			},
			resumeRecording () {
				this.$store.dispatch('screenRecorder/resumeRecording');
			},
			hideControls () {
				this.$store.dispatch('screenRecorder/hideControls');
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='screen-recorder-controls'] {
		position: fixed;
		z-index: 9999;
		right: 30px;
		top: 30px;
		border-radius:8px;
		padding: rem(20);

		&[data-transparent] {
			opacity: 0.25;
			transition: 0.15s ease-in-out;

			&:hover {
				opacity: 1;
			}
		}

		[data-element='actions'] {
			display: inline-flex;
			[data-component='btn']:not(:last-child) {
				margin-right: rem(10);
			}
			[data-element='recorder-button'] {
				[data-component='icon'] {
					width: rem(28);
					height: auto;
					margin: auto 0 auto auto;
					cursor: pointer;
					user-select: none;
				}

				&:not(:last-child) {
					margin-right: rem(10);
				}
			}
		}
		[data-component='checkbox-field'] {
			margin-top: rem(10);
		}
		[data-component='select-field'] {
			width: 225px;
			margin-top: rem(10);
		}
	}
</style>
